import { useEffect } from "react";
import React from 'react';

const Privacy = () => {
    useEffect(() => {
        document.body.scrollTop = 0;
    }, []);
    return (
      <div style={{padding:50}}>
        <h1>Politica sulla privacy</h1>
        <p>Ultimo aggiornamento: 06 aprile 2024</p>
        
        <p>Questa Politica sulla Privacy descrive le nostre politiche e procedure riguardanti la raccolta, l'uso e la divulgazione delle tue informazioni quando utilizzi il Servizio e ti informa sui tuoi diritti alla privacy e su come la legge ti protegge.</p>
        
        <p>Utilizziamo i tuoi dati personali per fornire e migliorare il Servizio. Utilizzando il Servizio, accetti la raccolta e l'uso delle informazioni in conformità con questa Politica sulla Privacy.</p>

        <h2>Interpretazione e definizioni</h2>
        <p><strong>Interpretazione</strong></p>
        <p>Le parole il cui iniziale è maiuscola hanno significati definiti nelle seguenti condizioni. Le seguenti definizioni hanno lo stesso significato indipendentemente che appaiano al singolare o al plurale.</p>

        <p><strong>Definizioni</strong></p>
        <p><strong>Account</strong> significa un account unico creato per te per accedere al nostro Servizio o a parti del nostro Servizio.</p>
        <p><strong>Affiliato</strong> significa un'entità che controlla, è controllata da o è sotto il controllo comune con una parte, dove "controllo" significa il possesso del 50% o più delle azioni, interessi patrimoniali o altri titoli aventi diritto di voto per l'elezione dei direttori o di un'altra autorità direttiva.</p>
        <p><strong>Azienda</strong> (indicata anche come "la Società", "Noi", "Il nostro" o "Il nostro" in questo Accordo) si riferisce a Sporting Club Tennis Saluzzo, Via S. Bernardino, 28, 12037 Saluzzo CN.</p>
        <p><strong>Cookies</strong> sono piccoli file che vengono inseriti sul tuo computer, dispositivo mobile o su qualsiasi altro dispositivo da un sito web, contenenti i dettagli della tua cronologia di navigazione su quel sito tra i suoi molteplici utilizzi.</p>
        <p><strong>Paese</strong> si riferisce a: Italia</p>
        <p><strong>Dispositivo</strong> significa qualsiasi dispositivo che può accedere al Servizio come un computer, un cellulare o un tablet digitale.</p>
        <p><strong>Dati personali</strong> sono tutte le informazioni che si riferiscono a un individuo identificato o identificabile.</p>
        <p><strong>Servizio</strong> si riferisce al Sito Web.</p>
        <p><strong>Prestatore di servizi</strong> significa qualsiasi persona fisica o giuridica che elabora i dati per conto della Società. Si riferisce a società o individui di terze parti impiegati dalla Società per facilitare il Servizio, per fornire il Servizio per conto della Società, per eseguire servizi correlati al Servizio o per assistere la Società nell'analisi di come il Servizio viene utilizzato.</p>
        <p><strong>Dati di utilizzo</strong> si riferisce ai dati raccolti automaticamente, generati dall'uso del Servizio o dall'infrastruttura del Servizio stesso (ad esempio, la durata di una visita alla pagina).</p>
        <p><strong>Sito Web</strong> si riferisce a Sporting Saluzzo, accessibile da https://sportingsaluzzo.it</p>
        <p><strong>Tu</strong> significa l'individuo che accede o utilizza il Servizio, o l'azienda o un'altra entità giuridica per conto della quale tale individuo accede o utilizza il Servizio, a seconda del caso.</p>

        <h2>Raccolta e utilizzo dei tuoi dati personali</h2>
        <h3>Tipi di dati raccolti</h3>
        <h4>Dati personali</h4>
        <p>Mentre utilizzi il nostro Servizio, potremmo chiederti di fornirci determinate informazioni personalmente identificabili che possono essere utilizzate per contattarti o identificarti. Le informazioni personalmente identificabili possono includere, ma non sono limitate a:</p>
        <ul>
            <li>Indirizzo email</li>
            <li>Nome e cognome</li>
            <li>Data di nascita</li>
        </ul>
        <h4>Dati di utilizzo</h4>
        <p>Utilizziamo alcuni dati degli utenti, come il nome, il cognome e più in generale lo username per mostrare le prenotazioni della piattaforma a tutti gli utenti registrati.</p>
        <h2>Tecnologie di tracciamento e cookie</h2>
        <p>I cookie possono essere "Persistenti" o "Di sessione". I cookie persistenti rimangono sul tuo computer personale o dispositivo mobile quando vai offline, mentre i cookie di sessione vengono eliminati non appena chiudi il tuo browser web. Scopri di più sui cookie sul sito web delle Politiche sulla Privacy.</p>
        <p>Non utilizziamo cookie, ma salviamo nel browser le credenziali (crittografate) dell'utente, in modo da permettere un accesso alla piattaforma automatico. </p>
        
        <h2>Contattaci</h2>
        <p>Se hai domande su questa Politica sulla Privacy, puoi contattarci:</p>
        <p>Per numero di telefono: 3334363911</p>
      </div>
    );
  }

export default Privacy;
