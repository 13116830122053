import React, {useState, useEffect, useContext, useRef} from 'react'
import {useNavigate, Link} from 'react-router-dom'
import ReCAPTCHA from "react-google-recaptcha";
import "./Login.css"
import {Account} from "./Main"
import {server, debug_mode} from "./Config"
import Alert from "./componenti/Alert"
import { SHA1 } from 'crypto-js';

const Login = () => {
    document.title="Sporting - Login/Registrati"

    const utente_user = useContext(Account);

    const [campi, setCampi] = useState([])
    const [signupBtn, setsignupBtn] = useState("Registrati")
    const [loginBtn, setloginBtn] = useState("Entra")
    const [errore, seterrore] = useState([false,""]);
    const [showpsw, setshowpsw] = useState("fa-solid fa-eye")
    const [rdnly, setrdnly] = useState(false)
    const [captchaValido, setCaptchaValido] = useState(false)

    const mioCaptcha = useRef(null)

    var nav=useNavigate()

    useEffect(() => {
        document.body.scrollTop = 0;
    }, []);

// INIZIO SCRIPT CAPTCHA
    const onChangeReCaptcha=()=>{
        if(mioCaptcha.current.getValue()){
            setCaptchaValido(true)
        }
    }
// FINE SCRIPT CAPTCHA

    const signup=()=>{
        if(signupBtn === "Registrati"){
            document.querySelector(".psw").style.display="block"
            document.querySelector("#rec_psw").style.display="none"
            document.querySelector("#user").value=""
            setrdnly(true)
            let lista=["Nome","Cognome","Data di nascita","Email"]
            let placeHolders=["Mario", "Rossi", "gg/mm/aaaa", "esempio@esempio.com"]
            let ids=["nome","cognome","nascita","email"]
            lista.forEach((element,i) => {
                let el=
                <div className='campo' key={element}>
                    <span>{element}</span>
                    <input type='text' name={element} id={ids[i]} placeholder={placeHolders[i]} onChange={userMake}></input>
                </div>
                setCampi(campi=>[...campi,el])
            })
            setCampi(campi=>[
                <h5 style={{fontSize:17,margin:0,padding:15,textAlign:"center",color:"var(--primary)"}}>Registrandoti accetti che i tuoi dati vengano utilizzati per questa piattaforma di prenotazione, per altre informazioni, visualizza la nostra <Link style={{fontSize:17,color:"var(--primary)"}} to="/privacy">Privacy Policy</Link></h5>,
                ...campi,
                <div className='socio-cont' key='socio'>
                    <span>Socio</span>
                    <label className="switch">
                        <input type="checkbox" id="socio" onChange={checkSocio} name="socio"/>
                        <span className="slider round"></span>
                    </label>
                    <span id="socio-info">Necessari certificato medico e 5€ per l'assicurazione</span>
                </div>
            ])
            setsignupBtn("Conferma")
            setloginBtn("Accedi")

        }else if(signupBtn==="Conferma"){ // Conferma registrazione
        // INIZIO SCRIPT CAPTCHA
            if(debug_mode) setCaptchaValido(true) //debug per prova locale
            if (captchaValido===true || debug_mode) {
                document.querySelector(".psw").style.display="block"
                let nome=document.querySelector("#nome").value.trim()
                let cognome=document.querySelector("#cognome").value.trim()
                let nascita=document.querySelector("#nascita").value.trim()
                let email=document.querySelector("#email").value.trim().toLowerCase()
                let username=document.querySelector("#user").value.trim().toLowerCase()
                let password=document.querySelector("#psw").value
                let socio=document.querySelector("#socio").checked
                var formData = new FormData();
                formData.append('nome', nome);
                formData.append('cognome', cognome)
                formData.append('nascita', nascita)
                formData.append('email', email)
                formData.append('username', username)
                formData.append('password', SHA1(password).toString())
                formData.append('socio', socio)
                let inputs={nome,cognome,nascita,email,username,password,socio}
                inputs.socio=socio
                let ck=checkinput(inputs)
                if (ck.stato){ // Input validi
                    let link=server['url']+"registra_utente.php"
                    fetch(link,{ method: 'POST', body: formData })
                    .then(function (response) {
                        return response.text();
                    })
                    .then(function (body) {
                        if(body.includes("ERRORE")){
                            if(body.includes("username"))
                            {
                                // Tentativo di utilizzo stesso username + ultime 2 cifre anno nascita
                                if(!document.querySelector("#user").value.includes(nascita.slice(-2)))
                                    document.querySelector("#user").value = `${document.querySelector("#user").value}${nascita.slice(-2)}`
                                formData.append('username', document.getElementById("user").value)
                                fetch(link,{ method: 'POST', body: formData })
                                .then(function (response) {
                                    return response.text();
                                })
                                .then(function (body) {
                                    if(debug_mode) console.log(body)
                                    if(body.includes("ERRORE")){
                                        seterrore([true,body])
                                        setTimeout(() => {
                                            seterrore([false,""])
                                        }, 1500);
                                    }
                                    else
                                    {
                                        username = document.getElementById("user").value;
                                        inputs={nome,cognome,nascita,email,username,password,socio}
                                        inputs.socio=socio
                                        let data=inputs
                                        let ps=document.getElementById("psw").value
                                        localStorage.setItem("username",username)
                                        localStorage.setItem("pwd",SHA1(ps).toString())
                                        if(username.includes("maestro")){
                                            localStorage.setItem("maestro",true)
                                        }else{
                                            localStorage.setItem("maestro",false)
                                        }
                                        if(data.socio){
                                            localStorage.setItem("socio",true)
                                        }else{
                                            localStorage.setItem("socio",false)
                                        }
                                        accedi_account(data)
                                    }
                                })
                            }
                            else{
                                seterrore([true,body])
                                setTimeout(() => {
                                    seterrore([false,""])
                                }, 1500);
                            }
                        }else{
                            let data=inputs;
                            let us=document.querySelector("#user").value.trim().toLowerCase()
                            let ps=document.querySelector("#psw").value
                            localStorage.setItem("username",us)
                            localStorage.setItem("pwd",SHA1(ps).toString())
                            if(us.includes("maestro")){
                                localStorage.setItem("maestro",true)
                            }else{
                                localStorage.setItem("maestro",false)
                            }
                            if(data.socio){
                                localStorage.setItem("socio",true)
                            }else{
                                localStorage.setItem("socio",false)
                            }
                            accedi_account(data)
                        }
                    });
                }
                else{
                    seterrore([true,ck.errore])
                    setTimeout(() => {
                        seterrore([false,""])
                    }, 1500);
                }
            }
            else {
                seterrore([true,"CAPTCHA Errato"])
                setTimeout(() => {
                    seterrore([false,""])
                }, 1500);
            }
            // FINE SCRIPT CAPTCHA
        }
        else if(signupBtn==="Invia Email"){
            let input={
                nome:"prova",
                cognome:"prova", 
                nascita:"00/00/0000",
                email:document.querySelector("#email").value,
                username:document.querySelector("#user").value,
                password:"Provaprova99!",
            }
            let check=checkinput(input)
            if(check.stato){
                let link=server['url']+"recupera_password.php"
                var formDataRec = new FormData();
                formDataRec.append('email', input.email);
                formDataRec.append('username', input.username)
                fetch(link,{ method: 'POST', body: formDataRec})
                .then(function (response) {
                    return response.text();
                })
                .then(function (body) {
                    if(body.includes("sbagliati")){
                        seterrore([true,"Dati non corretti"])
                        setTimeout(() => {
                            seterrore([false,""])
                        }, 1500);
                    }else if(body.includes("fallito")){
                        seterrore([true,"Invio email fallito"])
                        setTimeout(() => {
                            seterrore([false,""])
                        }, 1500);
                    }
                    else{
                        seterrore([true,"Email inviata"])
                        setTimeout(() => {
                            seterrore([false,""])
                        }, 1500);
                    }
                });
            }else{
                seterrore([true,"Ricontrolla i dati inseriti"])
                setTimeout(() => {
                    seterrore([false,""])
                }, 1500);
            }
        }
    }

    const checkSocio=()=>{
        document.querySelector("#socio-info").style.opacity = document.querySelector("#socio").checked? "1" : "0"
    }

    const userMake=()=>{
        if(document.querySelector("#nome").value.length>1 && document.querySelector("#cognome").value.length>1){
            let nm=document.querySelector("#nome").value.toLowerCase().replace(" ","")
            let cgnm=document.querySelector("#cognome").value.toLowerCase().replace(" ","")
            document.querySelector("#user").value=nm+"."+cgnm
        }
    }

    const checkinput=(inp)=>{
        if(inp.nome==="" || inp.nome.length<2){
            return {"stato":false,"errore":"Il nome non è abbastanza lungo, minimo 2 caratteri"} 
        }
        if(inp.cognome==="" || inp.cognome.length<2){
            return {"stato":false,"errore":"Il cognome non è abbastanza lungo, minimo 2 caratteri"} 
        }
        if(inp.nascita==="" || !inp.nascita.match(/^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/)){
            return {"stato":false,"errore":"Data di nascita non valida o assente"} 
        }
        if(inp.email==="" || !inp.email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )){
            return {"stato":false,"errore":"Email non valida o assente"} 
        }
        if(inp.username===""){
            return {"stato":false,"errore":"Utente registrato"} 
        }
        if(inp.password==="" || !inp.password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,17}$/)){
            document.querySelector("#info").style.opacity="1"
            document.querySelector("#info").style.height="auto"
            return {"stato":false,"errore":"Password non valida"} 
        }else{
            document.querySelector("#info").style.height="0"
            document.querySelector("#info").style.opacity="0"
        }
        return {"stato":true,"errore":""} 
    }

    const login=()=>{
        if(loginBtn === "Accedi"){
            document.querySelector(".psw").style.display="block"
            document.querySelector("#rec_psw").style.display="block"
            setrdnly(false)
            setCampi([])
            setloginBtn("Entra")
            setsignupBtn("Registrati")
        }else if(loginBtn === "Entra"){
            document.querySelector(".psw").style.display="block"
            let us=document.querySelector("#user").value.trim().toLowerCase()
            let ps=document.querySelector("#psw").value
            check_user(us,ps)
        }
    }

    const dati_utente=(user_cerca,user,psw)=>{
        let link=server['url']+"cerca_utente.php"
        var formDataUser = new FormData();
        formDataUser.append('cerca_username', user_cerca);
        formDataUser.append('username', user);
        formDataUser.append('password', SHA1(psw).toString())
        fetch(link,{ method: 'POST', body: formDataUser })
        .then(function (response) {
            return response.text();
        })
        .then(function (body) {
            if(debug_mode) console.log(body)
            if(!body.includes("errate")){
                let dati=JSON.parse(body)[0]
                accedi_account(dati)
                if(dati.username.includes("maestro")){
                    localStorage.setItem("maestro",true)
                }else{
                    localStorage.setItem("maestro",false)
                }
                if(dati.socio === "1"){
                    localStorage.setItem("socio",true)
                }else{
                    localStorage.setItem("socio",false)
                }
            }
        });
    }

    const accedi_account=(data)=>{
        if(debug_mode) console.log(data)
        document.querySelector("#user-nav").innerHTML=data.nome
        utente_user.setpersona(data)
        nav("/")
    }

    const check_user=async (us,ps)=>{
        let link=server['url']+"login.php"
        var formDataLogin = new FormData();
        formDataLogin.append('username', us);
        formDataLogin.append('password',SHA1(ps).toString())
        await fetch(link,{ method: 'POST', body: formDataLogin })
        .then(response=>response.text())
        .then(function (body) {
            if(debug_mode) console.log(body)
            if(body){
                dati_utente(us,us,ps)
                localStorage.setItem("username",us)
                localStorage.setItem("pwd",SHA1(ps).toString())
            }else{
                if(signupBtn!=="Conferma"){
                    seterrore([true,"Utente inesistente o password errata"])
                    setTimeout(() => {
                        seterrore([false,""])
                    }, 2000);
                }
            }
        });
    }

    const showPassword=()=>{
        let x=document.querySelector("#psw")
        if(x.type === "password"){
            x.type="text"
            setshowpsw("fa-solid fa-eye-slash")
        }else{
            x.type="password"
            setshowpsw("fa-solid fa-eye")
        }
        x.focus()
    }

    const recover=()=>{
        document.querySelector("#rec_psw").style.display="none"
        setsignupBtn("Invia Email")
        setloginBtn("Accedi")
        let el=
            <div className='campo' key={"Email"}>
                <span>Email</span>
                <input type='text' name="email" id="Email" placeholder="esempio@esempio.it"></input>
            </div>
        setCampi([el])
        document.querySelector(".psw").style.display="none"
    }

	return (
		<div id='login'>
            {errore[0] ? <Alert messaggio={errore[1]}/> : null}
			<div id="login-cont">
                <h1>Accedi o registrati</h1>
                {campi}
                 {/* INIZIO Captcha */}
                {signupBtn==="Conferma" &&(
                    <ReCAPTCHA
                        ref={mioCaptcha}
                        sitekey="6LcY1KQpAAAAAEb49UlazsSlb8DjR2H_5RoeHrlC"
                        onChange={onChangeReCaptcha}
                    />
                )}
                {/* FINE Captcha */}
                <form style={{width:"100%",display:"flex",alignItems:"center",flexDirection:"column"}}>
				<div className='campo'>
					<span>Username</span>
					<input type="text" name="username" autoComplete='on' id="user" readOnly={rdnly}/>
				</div>
                    <div className='campo psw'>
                        <span>Password</span>
                        <input type="password" autoComplete='on' onKeyDown={(e)=>{if(e.key === "Enter"){e.preventDefault();login()} }} name="password" id="psw"/>
                        <i className={showpsw} onClick={showPassword}></i>
                    </div>
                </form>
                <div id="info">
                La password deve contenere almeno un numero, un carattere speciale e una lettera maiuscola, deve contenere un minimo di 8 caratteri e un massimo di 16 caratteri
                </div>
                <div id="btn-login">
                    <input type="button" name="accedi" value={loginBtn} onClick={login}/>
                    <input type="button" name="accedi" value={signupBtn} onClick={signup}/>
                    <input type="button" name="accedi" id="rec_psw" value="Recupera password" onClick={recover}/>
                </div>
			</div>
			
		</div>
	)
}

export default Login