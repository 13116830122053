var debug_mode = false;

var server = { url:"" }

if(debug_mode)
{
    server.url = "http://localhost/SPORTING/php_files/";
    // server.url = "http://192.168.188.62/SPORTING/php_files/";
} 
else
    server.url = "https://www.sportingsaluzzo.it/php_files/";

export var server,debug_mode;
