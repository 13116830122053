import React from 'react'
import { NavLink,Link } from 'react-router-dom'
import "./Navbar.css"
import logo from "../img/loghi/logo-sporting.png"
import logoDenina from "../img/loghi/denina.png"
import logoItis from "../img/loghi/logoitis.png"

const Navbar = () => {
    return (
        <div id="navbar">
            <div className='logo'>
                <Link to=""><img src={logo} alt="logo"/></Link>
                <div style={{display:"flex",justifyContent:"center"}}>
                    <Link to="/su-di-noi" className='logo-den-link'><img src={logoDenina} className="logodenina" alt="logo"/></Link>
                    <Link to="/su-di-noi" className='logo-den-link'><img src={logoItis} className="logodenina" alt="logo"/></Link>
                </div>
            </div>
            <div id="links">
                <NavLink to="/">
                    <i className="fa-solid fa-home"></i>
                    <span>Home</span>
                </NavLink>
                <NavLink to="/prenota">
                    <i className="fa-solid fa-calendar"></i>
                    <span>Prenota</span>
                </NavLink>
                <NavLink to="/login" id="link_accedi">
                    <i className="fa-solid fa-user"></i>
                    <span id="user-nav">Accedi</span>
                </NavLink>
            </div>
        </div>
    )
}

export default Navbar