import React, { useEffect } from 'react'
import "./Home.css"
import logo from "./img/loghi/logo-sporting.png"
import { Link } from 'react-router-dom'
import sponsorBistrot from "./img/sponsor_bistrot.jpg"
import sponsor1 from "./img/sponsor1.jpg"
import sponsor2 from "./img/sponsor2.jpg"

const Home = () => {
    document.title="Sporting - Home"

    useEffect(() => {
        document.body.scrollTop = 0;
    }, []);

    return (
        <div id="home">
            <div id="precont">
                <div>
                    <div className='logo'>
                        <img src={logo} alt="logo"/>
                    </div>
                    <h1>Il tennis per tutti</h1>
                </div>
            </div>
            <div className="cont">
                <div className="cards">
                    <div className='card'>
                        <h2>Vieni a giocare con noi</h2>
                        <Link to="/prenota">Prenota ora</Link>
                    </div>
                </div>
                <div className="cards grid">
                    <div className='card sponsor bistrot'>
                        <img src={sponsorBistrot} alt="logo"/>
                    </div>
                    <div className='card sponsor sponsor1'>
                        <img src={sponsor1} alt="logo"/>
                    </div>
                    <div className='card sponsor sponsor2'>
                        <img src={sponsor2} alt="logo"/>
                    </div>
                </div>
                <hr/>
                <div className="info-cont">
                    <div className="img-home" id="image-contact">
                        <h2>Contattaci</h2>
                    </div>
                    <div className='info-text'>
                        <div>
                            <span className='label'>Telefono:</span><span>333-436-3911</span>
                        </div>
                        <div>
                            <span className='label'>Email:</span><span>marcotorello1979@libero.it</span>
                        </div>
                        <a href="tel:+39 333-436-3911">Chiama ora</a>
                    </div>
                    
                </div>
                <div className="info-cont">
                    <div className="img-home" id="image-place">
                        <h2>Dove siamo</h2>
                    </div>
                    <div className='info-text'>
                        <div>
                            <span className='label'>Via:</span><span> S. Bernardino, 28</span>
                        </div>
                        <div>
                            <span className='label'>Città:</span><span>Saluzzo</span>
                        </div>
                        <a href="https://goo.gl/maps/VFXDnC7rdTrXndiU9">Vieni a trovarci</a>
                    </div>
                </div>
                <hr/>
            </div>
        </div>
    )
}

export default Home