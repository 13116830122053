import React, { useEffect, useRef, useState, useContext } from 'react'
import "./Prenota.css"
import Alert from "./componenti/Alert"
import Informazioni from "./componenti/Informazioni"
import {Account} from "./Main"
import {server,debug_mode} from "./Config"
import campo1 from "./img/campo1.jpg"
import campo2 from "./img/touch.jpeg"
import campo3 from "./img/campo2.jpg"
var giorno_cliccato=null
var giorni_vedi=7
let allUserName=[];
var info_giorni_stringa=[] //1 mar oggi
var info_mesi_numero=[] //11
var info_ore_stringa=[] //'8 - 9'
var info_anni=[] //2022

const Prenota = () => {
    document.title="Sporting - Prenota"
    const utente_user = useContext(Account);
    var mesi=["Gennaio","Febbraio","Marzo","Aprile","Maggio","Giugno","Luglio","Agosto","Settembre","Ottobre","Novembre","Dicembre"];
    var num_giorni=[31,28,31,30,31,30,31,31,30,31,30,31]; // Giorni per mese
    var nomi_giorni=["lun","mar","mer","gio","ven","sab","dom"];
    var prenotazioni;
    var data_oggi=new Date()
    // data_oggi = new Date("2024-12-28"); // DEBUG
    var mese=data_oggi.getMonth()
    var giorno_oggi_num=data_oggi.getDate(); //es 10
    var primoGiorno = data_oggi.toLocaleDateString("it-EU", { weekday: 'short' })
    const doppio = useRef(null)
    const ospite = useRef(null)
    const [errore, seterrore] = useState([false,""]);
    const [caricato, setcaricato] = useState(true);
    const [orari, setorari] = useState([]);
    const [campi,setcampi] = useState([])
    const [giorni, setgiorni] = useState([]);
    const [giornipren, setgiornipren] = useState([]);
    const [prenot, setprenot] = useState([]);
    const [players, setplayers] = useState([]);

    useEffect(() => {
        document.body.scrollTop = 0;
        
        //Controllo anno bisestile, in caso febbraio 29 gg
        if(annoBisestile(data_oggi.getFullYear()))
            num_giorni[1] = 29;

        scaricaUserName();
        if(localStorage.getItem("username") !== null){
            document.querySelector("#no-accesso").style.display="none"
            crea_campi()
            if(!localStorage.getItem("username").includes("maestro") && localStorage.getItem("username").includes(".")){
                document.querySelector("#tutte_pren").style.display="none"
                document.querySelectorAll(".check-doppio")[1].style.display="none"
                document.querySelectorAll(".check-doppio")[0].style.margin="auto"
            }
        }else{
            document.querySelector("#sceltaCampi").style.display="none"
            document.querySelectorAll(".chiuso").forEach((c)=>{
                c.style.display="none"
            })
        }
    }, []);

    function annoBisestile(year) {
        if (year % 4 === 0) {
            if (year % 100 === 0) {
                if (year % 400 === 0) {
                    return true; // Divisibile per 400
                } else {
                    return false; // Divisibile per 100 ma non per 400
                }
            } else {
                return true; // Divisibile per 4 ma non per 100
            }
        } else {
            return false; // Non divisibile per 4
        }
    }

    const scaricaUserName = async() =>{
        let link=server['url']+"visualizza_utenti.php"
        let formDataPren = new FormData();
        formDataPren.append("username", localStorage.getItem("username"));
        formDataPren.append('password', localStorage.getItem("pwd"));
        await fetch(link,{ method: 'POST', body: formDataPren })
        .then(function (response) {
            return response.json();
        })
        .then(function (body) {
            allUserName=body.sort((a,b)=> (a.username > b.username ? 1 : -1))
            setplayers([<select className='gioc' key={121}>
                {
                    allUserName.map((elemento,index)=>{
                        if(localStorage.getItem("username")!==elemento.username || localStorage.getItem("username")==="maestro1" || localStorage.getItem("username")==="maestro2" || localStorage.getItem("username")==="maestro3")
                            return <option value={elemento.username} key={index}>{elemento.username}</option>
                        else 
                            return <></>
                    })   
                }
            </select>])
        })
    }

    const crea_campi=()=>{
        var formDataPren = new FormData();
        formDataPren.append("username", localStorage.getItem("username"));
        formDataPren.append('password', localStorage.getItem("pwd"));
        let link=server['url']+"visualizza_campi.php"
        fetch(link,{ method: 'POST', body: formDataPren })
        .then(function (response) {
            return response.text();
        })
        .then(function (body) {
            let data=JSON.parse(body)
            setcampi([])
            var campi_foto=[campo1,campo2,campo3]
            var campi_nomi=["Campo 1","Touchtennis","Campo 2"]
            data.forEach((cp,cont)=>{
                if(campi_foto[cont]===null) campi_foto[cont]=campi_foto[0]
                if(campi_nomi[cont]===null) campi_nomi[cont]="Campo"
                let el=<span key={campi_nomi[cont]} data-nome={campi_nomi[cont]}><img src={campi_foto[cont]} className="imgcampo" id={cont}  alt={cp.nome} onClick={seleziona}/></span>
                if(cont===0){
                    el=<span key={campi_nomi[cont]} data-nome={campi_nomi[cont]}><img src={campi_foto[cont]} className="imgcampo selected" id={cont}  alt={cp.nome} onClick={seleziona}/></span>
                }
                setcampi(campi=>[...campi,el])
            })
        });
    }

    const ins_pren=(data)=>{
        prenotazioni=data
    }

    const calcola_diff_giorni=(data1,data2)=>{
        if(data1==="oggi"){
            data1=data_oggi;
        }else{
            data1=new Date(data1.split("/")[2],parseInt(data1.split("/")[1])-1,data1.split("/")[0]);
        }
        if(data2==="oggi"){
            data2=data_oggi;
        }else{
            data2=new Date(data2.split("/")[2],parseInt(data2.split("/")[1])-1,data2.split("/")[0]);
        }
        let diffTime = data2-data1;
        var diffGiorni = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        
        return diffGiorni
    }

    const ins_pren_popup=(data)=>{
        let no_pren=true
        setprenot([])
        // data.sort((a,b)=>(parseInt(a.data.split("/")[1])) - parseInt(b.data.split("/")[1])).reverse() //ordina per mese
        let m_temp=[]
        let cont=0
        let mettere_eti=true
        data.forEach(pren => {
            let pren_vecchia=false
            let gio=calcola_diff_giorni("oggi",pren.data)
            if(gio<0){
                pren_vecchia=true
            }
            if(!pren_vecchia){
                let m=parseInt(pren.data.split("/")[1])
                let eti =<h3 data-month={m} key={m+Math.random()} className={mesi[m-1]}>{mesi[m-1]}</h3>
                if(m_temp.indexOf(m)===-1){
                    m_temp.push(m)
                    mettere_eti=true
                }
                else{
                    mettere_eti=false
                }
                let el=<div className='prenotazione' key={pren.IDPrenota} data-id={pren.IDPrenota} data-data={pren.data} data-user={pren.username} data-ora={pren.ora} data-campo={pren.nomeCampo}>
                    <div className='campi-pren'>
                        <div className='campo-pren'>
                            <span>Username:</span><span>{pren.username}</span>
                        </div>
                        <div className='campo-pren'>
                            <span>Data:</span><span>{pren.data}</span>
                        </div>
                        <div className='campo-pren'>
                            <span>Ora:</span><span>{pren.ora}</span>
                        </div>
                        <div className='campo-pren'>
                            <span>Giocatori:</span>
                            <span>{pren.username.includes("maestro") ? pren.nome.split(" ")[0].toLowerCase() : pren.cognome.split(" ")[0].toLowerCase()}</span>
                            <span>{pren.giocatore2.includes("maestro") ? pren.giocatore2 : pren.giocatore2.split(".")[1]}</span>
                            {pren.giocatore3!==null ? <span>{pren.giocatore3.split(".")[1]}</span> : null}
                            {pren.giocatore4!==null ? <span>{pren.giocatore4.split(".")[1]}</span> : null}
                        </div>
                        <div className='campo-pren'>
                            <span>Campo:</span><span>{pren.nomeCampo}</span>
                        </div>
                        <div className='campo-pren'>
                            <span>Prenotato il:</span><span>{pren.dataprenotazione.split(" ")[0].split("-")[2]}/{pren.dataprenotazione.split(" ")[0].split("-")[1]}/{pren.dataprenotazione.split(" ")[0].split("-")[0]}</span>
                        </div>
                    </div>
                    <i className="fa-solid fa-trash-can" data-month={m} data-data={pren.data} data-ora={pren.ora} onClick={cancellaPren}></i>
                </div>
                if(mettere_eti || cont===0){
                    setprenot(prenot=>[...prenot, eti, el])
                }else{
                    setprenot(prenot=>[...prenot, el])
                }
                no_pren=false
                cont++
            }
        })
        if(no_pren || cont===0){
            setprenot([<h1 key={Math.random()}>Non ci sono prenotazioni</h1>])
        }
    }

    const cancellaPren=(e)=>{
        let link=server['url']+"cancella_prenotazione.php"

        let formDataPren = new FormData();
        formDataPren.append("idprenota", e.target.parentElement.dataset.id);
        formDataPren.append("username", localStorage.getItem("username"));
        formDataPren.append('password', localStorage.getItem("pwd"));

        fetch(link,{ method: 'POST', body: formDataPren })
        .then(function (response) {
            return response.text();
        })
        .then(function (body) {
            if(body.includes("eliminata"))
            {
                let d=e.target.parentElement.dataset
                invia_mail("maestro","Cancellazione di prenotazione","E' stata cancellata una prenotazione per il giorno "+d.data+" alle ore "+d.ora+" per il campo "+d.campo + " da "+d.user)
            }
        });
        try {
            e.target.parentElement.style.display="none"
            if(document.querySelectorAll("[data-month='"+e.target.dataset.month+"']").length-2===0){
                document.querySelector("h3[data-month='"+e.target.dataset.month+"']").style.display="none"
            }
            e.target.dataset.month="-1"
            let conta=0
            document.querySelectorAll("#pren-cont *").forEach((pren)=>{
                if(pren.style.display==="none"){
                    conta++
                }
            })
            if(e.target.parentElement.parentElement.children.length===conta){
                setprenot([<h1 key={Math.random()}>Non ci sono prenotazioni</h1>])
            }
        } catch (error) {
        }
    }

    const ricevi_pren=(x,funz=()=>{},param=0)=>{
        let link=server['url']+"visualizza_prenotazioni.php"
        if(x==="singola"){
            link=server['url']+"visualizza_prenotazione.php"
        }

        let formDataPren = new FormData();
        formDataPren.append("username", localStorage.getItem("username"));
        formDataPren.append('password', localStorage.getItem("pwd"));
        fetch(link,{ method: 'POST', body: formDataPren })
        .then(function (response) {
            return response.text();
        })
        .then(function (body) {
            if(debug_mode) console.log(body)

            if(body.includes("NON")){
                ins_pren_popup([])
                ins_pren([])
            }else{
                try {
                    ins_pren(JSON.parse(body))
                    ins_pren_popup(JSON.parse(body))
                } catch (error) {
                    if(debug_mode) console.log(error)
                    ins_pren_popup([])
                    ins_pren([])
                }
            }
            
            if(funz!==null && param!==null){
                funz(param)
            }
        });
    }

    const crea_giorni_titoli=()=>{
        if(localStorage.getItem("maestro") === "true"){
            giorni_vedi=21
        }
        else
        {
            if(localStorage.getItem("socio") === "true"){
                giorni_vedi=14
            }else{
                giorni_vedi=7
            }
        }

        setgiorni([])
        info_mesi_numero=[]
        info_giorni_stringa=[]
        info_anni=[]
        document.querySelector("#giorni").style.gridTemplateColumns="repeat("+giorni_vedi+",90px)"
        let ms=mese+1;
        let n_giorno=giorno_oggi_num;
        let num_giorni_mese=num_giorni[mese];
        let j=nomi_giorni.indexOf(primoGiorno);
        let anno=data_oggi.getFullYear()
        for (let i = 0; i < giorni_vedi; i++) {
            let el;
            if(n_giorno===num_giorni_mese+1){
                n_giorno=1;
                if(ms===12){
                    ms=1;
                    anno+=1;
                }else{
                    ms+=1;
                }
            }
            el =
            <div className='giorno titolo' id={i+1 + " " + nomi_giorni[j]} data-mese={ms} key={i+Math.random()} onClick={giorno_click}>
                <span>{n_giorno}</span>
                <span>{nomi_giorni[j]}</span>
            </div>
            if(i===0){
                el=
                <div className='giorno oggi titolo' id={`${i+1 + " " + nomi_giorni[j]} oggi`} data-mese={ms}  key={i+Math.random()} onClick={giorno_click}>
                    <span>{n_giorno}</span>
                    <span>{primoGiorno}</span>
                </div>
                info_giorni_stringa.push(`${n_giorno} ${nomi_giorni[j]} oggi`)
            }else{
                info_giorni_stringa.push(`${n_giorno} ${nomi_giorni[j]}`)
            }
            info_mesi_numero.push(ms)
            info_anni.push(anno)
            n_giorno+=1;
            j+=1;
            if(j===nomi_giorni.length){
                j=0;
            }
            setgiorni(giorni => [...giorni, el])
        }
    }
    var fascia=""
    const giorno_click_funz=(e)=>{
        let appari=true
        let socio=true
        let contaSocioPren=0
        let contaSocioPrenGrigio=0
        let contaNonSocioPren=0
        let contaNonSocioPrenGrigio=0

        socio = localStorage.getItem("socio") === "true" ? true : false;

        if(localStorage.getItem("maestro") === "false"){ //non è maestro
            if(!socio){ //non socio
                let data2=new Date(e.target.dataset.stringa.split("/")[2],e.target.dataset.stringa.split("/")[1]-1,e.target.dataset.stringa.split("/")[0]); //anno,mese,giorno del bottone cliccato
                let diffTime = Math.abs(data2 - data_oggi);
                let diffGiorni = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                if(diffGiorni>2){ //finchè i giorni di differenza non sono uguali a 2 non posso prenotare
                    seterrore([true,"Non sei socio, puoi prenotare solo fino a 2 giorni di distanza"])
                    setTimeout(() => {
                        seterrore([false,""])
                    }, 2000);
                    appari=false
                } else {
                    // controllo non socio
                    prenotazioni.forEach((pr)=>{
                        if(utente_user.persona.username === pr.username){
                            let d=new Date(pr.ORARIO)
                            let diffTime =d-data_oggi
                            let diffMinuti = Math.ceil(diffTime / (1000 * 60));
                            if(debug_mode) console.log(d,diffMinuti)
                                contaNonSocioPren++
                                if(pr.ora.split(":")[0]>=12 && pr.ora.split(":")[0]<15 || pr.ora.split(":")[0]>=17){
                                    contaNonSocioPrenGrigio++
                                }
                            if(diffMinuti<-60){
                                if(debug_mode) console.log("è passata 1 ora")
                                contaNonSocioPren--
                                if(pr.ora.split(":")[0]>=12 && pr.ora.split(":")[0]<15 || pr.ora.split(":")[0]>=17){
                                    contaNonSocioPrenGrigio--
                                }
                            }
                        }
                    })
                }
            }else if(socio){
                prenotazioni.forEach((pr)=>{
                    if(utente_user.persona.username===pr.username){
                        let d=new Date(pr.ORARIO)
                        let diffTime =d-data_oggi
                        let diffGiorni = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        let diffMinuti = Math.ceil(diffTime / (1000 * 60));
                        if(debug_mode) console.log(d,diffMinuti)
                        if(diffGiorni>=0){
                            contaSocioPren++
                            if(pr.ora.split(":")[0]>=12 && pr.ora.split(":")[0]<15 || pr.ora.split(":")[0]>=17){
                                contaSocioPrenGrigio++
                            }
                        }
                        if(diffMinuti<-60){
                            if(debug_mode) console.log("è passata 1 ora")
                            contaSocioPren--
                            if(pr.ora.split(":")[0]>=12 && pr.ora.split(":")[0]<15 || pr.ora.split(":")[0]>=17){
                                contaSocioPrenGrigio--
                            }
                        }
                    }
                })
            }
            let ora_giorno_cliccato_fascia=parseInt(e.target.dataset.ora.split("-")[0])
            // controllo numero di prenotazioni socio
            if(contaSocioPren===2){
                appari=false
                seterrore([true,"Hai già prenotato 2 volte per queste 2 settimane, gioca per prenotare nuovamente"])
                setTimeout(() => {
                    seterrore([false,""])
                }, 2200);
            }else if(contaSocioPrenGrigio>0 && (ora_giorno_cliccato_fascia>=12 && ora_giorno_cliccato_fascia<15 || ora_giorno_cliccato_fascia>=17)){
                appari=false
                seterrore([true,"Hai già prenotato 1 ora in fascia grigia, puoi prenotare per quest'ora non appena avrai giocato"])
                setTimeout(() => {
                    seterrore([false,""])
                }, 2500);
            }
            // controllo del numero di prenotazioni non socio
            if(contaNonSocioPren===2){
                appari=false
                seterrore([true,"Hai già prenotato 2 volte per questa settimana, gioca per prenotare nuovamente"])
                setTimeout(() => {
                    seterrore([false,""])
                }, 2200);
            }else if(contaNonSocioPrenGrigio>0 && (ora_giorno_cliccato_fascia>=12 && ora_giorno_cliccato_fascia<15 || ora_giorno_cliccato_fascia>=17)){
                appari=false
                seterrore([true,"Hai già prenotato 1 ora in fascia grigia, puoi prenotare per quest'ora non appena avrai giocato"])
                setTimeout(() => {
                    seterrore([false,""])
                }, 2500);
            }
            var conta=0
            prenotazioni.forEach((pre)=>{
                if(utente_user.persona.username===pre.username){
                    let d=new Date(pre.ORARIO)
                    let diffTime =d-data_oggi
                    let diffMinuti = Math.ceil(diffTime / (1000 * 60));
                    conta++
                    if(diffMinuti<-60){
                        if(debug_mode) console.log("è passata 1 ora")
                        conta--
                    }
                }
            })
            if(document.querySelector(".selected").alt!=="TOUCHTENNIS"){
                document.querySelectorAll(".orar").forEach((o)=>{
                    if(o.textContent===e.target.dataset.ora){
                        if(o.classList.value.includes("grigia")){
                            fascia="grigia"
                        }else{
                            fascia="bianca"
                        }
                    }
                })
            }
        }
        if(appari){
            giorno_cliccato=e.target
            document.querySelector("#giorno-pren").textContent=giorno_cliccato.dataset.giorno
            document.querySelector("#ora-pren").textContent=giorno_cliccato.dataset.ora
            document.querySelector("#popup-prenota").classList.remove("chiuso")
            document.querySelectorAll(".conf_btn").forEach((el)=>{
                el.disabled=false
            })
        }
    }
    const giorno_click=(e)=>{
        if(e.target.classList.value.includes("giorno_tab")){
            ricevi_pren("tutte",giorno_click_funz,e)
        }
    }

    const prenotazione=()=>{
        document.querySelectorAll(".conf_btn").forEach((btn)=>{
            btn.disabled=true
        })
        if(document.querySelector(".selected").alt!=="TOUCHTENNIS"){
            document.querySelectorAll(".orar").forEach((o)=>{
                if(debug_mode) console.log(giorno_cliccato)
                if(o.textContent===giorno_cliccato.dataset.ora){
                    if(o.classList.value.includes("grigia")){
                        fascia="grigia"
                    }else{
                        fascia="bianca"
                    }
                }
            })
        }
        siprenota_funz()
    }

    var valido=true

    const siprenota_funz=()=>{
        let time_array=giorno_cliccato.dataset.ora.split(" - ")
        let time1= time_array[0].includes(":") ? time_array[0] : time_array[0]+":00"
        let time2= time_array[1].includes(":") ? time_array[1] : time_array[1]+":00"
        let time=time1+","+time2
        let formDataPren = new FormData();
        if(ospite.current.checked){
            formDataPren.append("username", "ospite.ospite");
            formDataPren.append('giocatore2',  localStorage.getItem("username"));
        }else{
            formDataPren.append("username", localStorage.getItem("username"));
        }
        formDataPren.append('password', localStorage.getItem("pwd"));
        let campo=document.querySelector(".selected").alt
        formDataPren.append('nomecampo', campo);
        formDataPren.append('data', giorno_cliccato.dataset.stringa);
        // formDataPren.append('data',"26/6/2022") // Debug test
        formDataPren.append('ora', time);
        formDataPren.append('fascia', fascia);
        let ind=2
        let j=0
        if(!ospite.current.checked){
            document.querySelectorAll(".gioc").forEach(gioc => {
                if(gioc.value !== ""){
                    let link=server['url']+"cerca_utente.php"

                    let formDataPrenCheck = new FormData();
                    formDataPrenCheck.append("cerca_username", gioc.value.toLowerCase());
                    formDataPrenCheck.append("username", localStorage.getItem("username"));
                    formDataPrenCheck.append('password', localStorage.getItem("pwd"));
                    
                    fetch(link,{ method: 'POST', body: formDataPrenCheck })
                    .then(function (response) {
                        return response.text()
                    })
                    .then(function (body) {
                        if(body.includes("non")){
                            valido=false             
                        }
                        if(j===document.querySelectorAll(".gioc").length-1){
                            if(valido){
                                let formDataPrenCheckPlayers = new FormData();
                                formDataPrenCheckPlayers.append("fascia", fascia);
                                formDataPrenCheckPlayers.append("username", gioc.value.toLowerCase());
                                // CONTROLLO FASCE GIOCATORE
                                let link=server['url']+"check_user_can_book.php"
                                fetch(link,{ method: 'POST', body: formDataPrenCheckPlayers })
                                .then(function (response) {
                                    return response.json();
                                })
                                .then(function (sipuo) {
                                    if(debug_mode) console.log(sipuo)
                                    if(sipuo){
                                        link=server['url']+"prenota_campo.php"
                                        fetch(link,{ method: 'POST', body: formDataPren }) //PRENOTAZIONE
                                        .then(function (response) {
                                            return response.text();
                                        })
                                        .then(function (body) {
                                            if(debug_mode) console.log(body)
                                            if(body.includes("Campo prenotato")){
                                                chiudi_pren();
                                                document.querySelectorAll(".conf_btn").forEach((btn)=>{
                                                    btn.disabled=false
                                                })
                                            }
                                            else if(body.includes("Campo gia prenotato")){
                                                seterrore([true,"Il campo è appena stato prenotato da qualcun'altro!"])
                                                setTimeout(() => {
                                                    seterrore([false,""])
                                                    chiudi_pren();
                                                    document.querySelectorAll(".conf_btn").forEach((btn)=>{
                                                        btn.disabled=false
                                                    })
                                                }, 2000);
                                            }
                                            else{
                                                seterrore([true,"Prenotazione fallita"])
                                                setTimeout(() => {
                                                    seterrore([false,""])
                                                    chiudi_pren();
                                                    document.querySelectorAll(".conf_btn").forEach((btn)=>{
                                                        btn.disabled=false
                                                    })
                                                }, 1600);
                                            }
                                            // invia_mail(utente_user.persona.email,"Avviso di prenotazione","Hai prenotato il campo "+campo+" per il giorno "+giorno_cliccato.dataset.stringa+" alle ore "+time+".")
                                        });
                                    }else{
                                        seterrore([true,"Presenti giocatori che non possono prenotare (limite raggiunto)"])
                                        setTimeout(() => {
                                            seterrore([false,""])
                                            document.querySelectorAll(".conf_btn").forEach((btn)=>{
                                                btn.disabled=false
                                            })
                                        }, 1600);
                                    }
                                });
                            }
                            else{
                                seterrore([true,"Presenti username non esistenti"])
                                setTimeout(() => {
                                    seterrore([false,""])
                                    document.querySelectorAll(".conf_btn").forEach((btn)=>{
                                        btn.disabled=false
                                    })
                                }, 1500);
                            } 
                        }else{
                            j++
                        }
                    });
                    formDataPren.append('giocatore'+ind, gioc.value.toLowerCase());
                }
                ind++
            })
        }else{
            let link=server['url']+"prenota_campo.php"
            fetch(link,{ method: 'POST', body: formDataPren })
            .then(function (response) {
                return response.text();
            })
            .then(function (body) {
                if(debug_mode) console.log(body)
                if(body.includes("Campo prenotato")){
                    chiudi_pren()
                }
                else if(body.includes("Campo gia prenotato")){
                    seterrore([true,"Il campo è appena stato prenotato da qualcun'altro!"])
                    setTimeout(() => {
                        seterrore([false,""])
                    }, 2000);
                }
                else{
                    seterrore([true,"Prenotazione fallita"])
                    setTimeout(() => {
                        seterrore([false,""])
                    }, 1600);
                }
                //invia_mail(utente_user.persona.email,"Avviso di prenotazione","Hai prenotato il campo "+campo+" per il giorno "+giorno_cliccato.dataset.stringa+" alle ore "+time+".")
            });
        }
    }
    const invia_mail=(dest,og,msg)=>{
        let link=server['url']+"invio-email.php"
        var formDataInvia = new FormData();
        if(dest==="maestro1"){
            dest="marcotorello1979@libero.it"
        }
        formDataInvia.append('destinatario', dest);
        formDataInvia.append('oggetto', og);
        formDataInvia.append('messaggio', msg);
        formDataInvia.append('username', localStorage.getItem("username"));
        formDataInvia.append('password', localStorage.getItem("pwd"));
        fetch(link,{ method: 'POST', body: formDataInvia })
        .then(function (response) {
            return response.text();
        })
        .then(function (body) {
        });
    }
    const seleziona=(e)=>{
        document.querySelectorAll(".imgcampo").forEach(img => {
            img.classList.remove("selected")
        })
        e.target.classList.toggle("selected")
        document.querySelector("#campoNomeScelto").textContent=e.target.parentElement.dataset.nome
        conferma()
    }
    const conferma=()=>{
        aggiorna_orari(parseInt(document.querySelector(".selected").id))
        crea_giorni_titoli()
        document.querySelector("#sceltaCampi").style.opacity="0"
        setcaricato(false);
        setTimeout(() => {
            document.querySelector("#sceltaCampi").style.display="none"
            document.querySelector("#campoScelto").style.display="flex"
            document.querySelector("#tabella-cont").style.display="flex"
        }, 300);
    }
    const aggiorna_orari=(num)=>{
        setorari([])
        info_ore_stringa=[]
        var formDataPren = new FormData();
        formDataPren.append("username", localStorage.getItem("username"));
        formDataPren.append('password', localStorage.getItem("pwd"));
        let link=server['url']+"visualizza_campi.php"
        fetch(link,{ method: 'POST', body: formDataPren })
        .then(function (response) {
            return response.text();
        })
        .then(function (body) {
            let data=JSON.parse(body)
            let ore_campo=data[num].fascia_oraria.split(",")
            let j=0
            ore_campo.forEach(ora => {
                if(ore_campo.indexOf(ora)!==ore_campo.length-1){
                    let or=<div key={ora} className='orar'>{ora} - {ore_campo[ore_campo.indexOf(ora)+1]}</div>
                    if(document.querySelector(".selected").alt!=="TOUCHTENNIS"){
                        if((parseInt(ora)>=12 && parseInt(ora)<15) || parseInt(ora)>=17){
                            or=<div key={ora} className='grigia orar'>{ora} - {ore_campo[ore_campo.indexOf(ora)+1]}</div>
                        }
                        if(parseInt(ora)>=8 && parseInt(ora)<12){
                            or=<div key={ora} className='bianca orar'>{ora} - {ore_campo[ore_campo.indexOf(ora)+1]}</div>
                        }
                    }
                    info_ore_stringa.push(`${ora} - ${ore_campo[ore_campo.indexOf(ora)+1]}`)
                    setorari(orari=>[...orari,or])
                }
                if(j===ore_campo.length-1){
                    crea_giorni_tabella(giorni_vedi,ore_campo.length)
                }else{
                    j++
                }
            });
        });
    }
    const dati_giorni_tabella=(ind_giorno,ind_orario)=>{
        let giorno_stringa=info_giorni_stringa[ind_giorno]
        let num_mese=info_mesi_numero[ind_giorno]
        let ora_stringa=info_ore_stringa[ind_orario]
        let anno=info_anni[ind_giorno]

        let data_stringa=parseInt(giorno_stringa)+"/"+num_mese+"/"+anno
        let ora_pren = ""
        try {
            ora_pren=ora_stringa.split(" - ")[1]
        } catch (error) {
            if(debug_mode) console.log(ind_giorno,ind_orario,info_giorni_stringa,info_ore_stringa,info_anni)
        }
        let ora_pren_num=parseInt(ora_pren)
        let giorno_num=parseInt(giorno_stringa)
        let mese_num=parseInt(num_mese)

        return {
            giorno_stringa,
            num_mese,
            ora_stringa,
            anno,
            data_stringa,
            ora_pren,
            ora_pren_num,
            giorno_num,
            mese_num
        };
    }

    const crea_giorni_tabella_funz=(par)=>{
        var n=par.n //giorni
        var num_orari=par.num_orari
        var campo=document.querySelector(".selected").alt
        var pren={
            giorni:[],
            mesi:[],
            ore:[],
            nomi:[],
            cognomi:[]
        }
        if(prenotazioni.length===undefined){
            prenotazioni=[]
        }
        //if(prenotazioni!==[]){
            for (let i = 0; i < prenotazioni.length; i++) {
                if(prenotazioni[i].nomeCampo===campo && prenotazioni[i].username!=="aaaaa"){
                    let grn=parseInt(prenotazioni[i].data.split("/")[0])
                    let mese_pren=parseInt(prenotazioni[i].data.split("/")[1])
                    let r= prenotazioni[i].ora.split(",")[1].includes(":00") ? prenotazioni[i].ora.split(",")[1].split(":")[0] : prenotazioni[i].ora.split(",")[1]
                    pren.giorni.push(grn)
                    pren.mesi.push(mese_pren)
                    pren.ore.push(r)

                    if(debug_mode) console.log(prenotazioni[i])

                    if(prenotazioni[i].giocatore2==="maestro1")
                    {
                        pren.nomi.push("Maestro1")
                        pren.cognomi.push("1")
                    }
                    else if(prenotazioni[i].giocatore3==="maestro1")
                    {
                        pren.nomi.push("Maestro1")
                        pren.cognomi.push("1")
                    }
                    else if(prenotazioni[i].giocatore4==="maestro1")
                    {
                        pren.nomi.push("Maestro1")
                        pren.cognomi.push("1")
                    }

                    else if(prenotazioni[i].giocatore2==="maestro2")
                    {
                        pren.nomi.push("Maestro2")
                        pren.cognomi.push("2")
                    }
                    else if(prenotazioni[i].giocatore3==="maestro2")
                    {
                        pren.nomi.push("Maestro2")
                        pren.cognomi.push("2")
                    }
                    else if(prenotazioni[i].giocatore4==="maestro2")
                    {
                        pren.nomi.push("Maestro2")
                        pren.cognomi.push("2")
                    }

                    else if(prenotazioni[i].giocatore2==="maestro3")
                    {
                        pren.nomi.push("Maestro3")
                        pren.cognomi.push("3")
                    }
                    else if(prenotazioni[i].giocatore3==="maestro3")
                    {
                        pren.nomi.push("Maestro3")
                        pren.cognomi.push("3")
                    }
                    else if(prenotazioni[i].giocatore4==="maestro3")
                    {
                        pren.nomi.push("Maestro3")
                        pren.cognomi.push("3")
                    }
                    else
                    {
                        pren.nomi.push(prenotazioni[i].nome)
                        pren.cognomi.push(prenotazioni[i].cognome)
                    }
                }
            }
        //}
        var array=[];
        setgiornipren([])

        for (let ind_orario = 0; ind_orario < num_orari-1; ind_orario++) {
            for (let ind_giorno = 0; ind_giorno < n; ind_giorno++) {
                let info=dati_giorni_tabella(ind_giorno,ind_orario)
                let el=<input type="button" className="giorno_tab" data-stringa={info['data_stringa']} data-mese={info['num_mese']} disabled={false} data-giorno={info['giorno_stringa']} data-ora={info['ora_stringa']} name="prenota" value="Prenota" onClick={giorno_click} key={ind_orario+ind_giorno+Math.random()}/>
                if(debug_mode) console.log(info)
                if(info['giorno_stringa'].includes("oggi") && info['ora_pren_num']<=data_oggi.getHours()){
                    el=<input type="button" className="giorno_tab" data-stringa={info['data_stringa']} data-mese={info['num_mese']} disabled={true} data-giorno={info['giorno_stringa']} data-ora={info['ora_stringa']} name="prenota" value="Prenota" onClick={giorno_click} key={ind_orario+ind_giorno+Math.random()}/>
                }
                // controllo tutte le prenotazioni per vedere se per un determinato giorno e ora ci sono prenotazioni
                for (let j = 0; j < pren.giorni.length; j++) {
                    const p = pren.giorni[j]; 
                    if(p===info['giorno_num']){
                        if(pren.mesi[j]===info['mese_num'] && pren.ore[j]===info['ora_pren']){
                            if(debug_mode) console.log(pren,j)
                            let nm=pren.nomi[j][0].toUpperCase()+". "+pren.cognomi[j]
                            el=<input type="button" data-giorno={info['giorno_stringa']} data-stringa={info['data_stringa']} data-mese={info['num_mese']} disabled={true} data-ora={info['ora_stringa']} name="prenota" value={nm} onClick={giorno_click} key={ind_orario+ind_giorno+Math.random()}/>
                            break; //esco dal for se trovo prenotazione per il bottone
                        }
                    }
                }
                array.push(el)
            }
        }
        setgiornipren(array)
        setcaricato(true)
    }
    
    const crea_giorni_tabella=(n,num_orari)=>{
        ricevi_pren("tutte",crea_giorni_tabella_funz,{n,num_orari})
    }

    const popup_vedi_pren=(x)=>{ // X è la tipologia, "tutte" o "singola"
        document.querySelector("#popup-vedi-pren").classList.remove("chiuso")
        document.querySelector("#tutte_pren").disabled=true
        ricevi_pren(x)
    }

    const chiudi_vedi_pren=()=>{
        document.querySelector("#popup-vedi-pren").classList.add("chiuso")
        document.querySelector("#tutte_pren").disabled=false
        setprenot([])
        setTimeout(()=>{
            crea_giorni_tabella(giorni_vedi,document.querySelectorAll(".orar").length+1)
        },400)
    }

    const chiudi_pren=()=>{
        document.querySelector("#popup-prenota").classList.add("chiuso")
        setTimeout(()=>{
            crea_giorni_tabella(giorni_vedi,document.querySelectorAll(".orar").length+1)
        },400)
    }

    const apri_info=()=>{
        document.querySelector("#informazioni").classList.remove("chiuso")
    }

    const aggiungi_players=()=>{
        setplayers([<select className='gioc'>
            {
            allUserName.map((elemento,index)=>{
                if(elemento.username!==localStorage.getItem("username"))
                    return <option value={elemento.username} key={index}>{elemento.username}</option>
                else 
                    return <></>
            })   
            }
            </select>])
        if(doppio.current.checked){
            setplayers([<select className='gioc'>
            {
            allUserName.map((elemento,index)=>{
                if(elemento.username!==localStorage.getItem("username"))
                    return <option value={elemento.username} key={index}>{elemento.username}</option>
                else 
                    return <></>
            })   
            }
            </select>,<select className='gioc'>
            {
            allUserName.map((elemento,index)=>{
                if(elemento.username!==localStorage.getItem("username"))
                    return <option value={elemento.username} key={index}>{elemento.username}</option>
                else 
                    return <></>
            })   
            }
            </select>,<select className='gioc'>
            {
            allUserName.map((elemento,index)=>{
                if(elemento.username!==localStorage.getItem("username"))
                    return <option value={elemento.username} key={index}>{elemento.username}</option>
                else 
                    return <></>
            })   
            }
            </select>])
        }
    }
    return (
        <div id="prenota">
            {errore[0] ? <Alert messaggio={errore[1]}/> : null}
            {!caricato?<h1 style={{textAlign:"center",marginTop:"50px"}}>Caricamento...</h1>:null}
            <div id="popup-prenota" className='chiuso'>
                <i className="fa-solid fa-close" onClick={chiudi_pren}></i>
                <h1>Prenota</h1> 
                <input type="button" name="conf" id="conferma" className="conf_btn" value="Conferma" onClick={prenotazione}/>
                <div id='info-pren'>
                    <div id="giorno-pren">
                        1 gio
                    </div>
                    <div id="ora-pren">
                        8:00
                    </div>
                </div>
                <div id="cont-check">
                    <div className="check-doppio">
                        <span><input type="checkbox" ref={doppio} id='doppio' name="doppio" onChange={aggiungi_players} value="Doppio"/></span>
                        <label>doppio</label>
                    </div>
                    <div className="check-doppio">
                        <span><input type="checkbox" ref={ospite} id='ospite' name="doppio" value="Doppio"/></span>
                        <label>Prenota per ospite</label>
                    </div>
                </div>
                <h2>Inserisci gli username degli altri giocatori (nome.cognome):</h2>
                <div id="giocatori">
                    {players}
                </div>
                <input type="button" name="conf" className="conf_btn" value="Conferma" onClick={prenotazione}/>
            </div>
            <div id="popup-vedi-pren" className='chiuso'>
                <i className="fa-solid fa-close" onClick={chiudi_vedi_pren}></i>
                <h2>Prenotazioni</h2>
                <div id="pren-cont">
                    {prenot}
                </div>
            </div>
            <div id="no-accesso">
                Effettua l'accesso per prenotare
            </div>
            <div id="sceltaCampi">
                <h1>Seleziona il campo</h1>
                <div id="campi-img">
                    {campi}
                </div>
            </div>
            <div id="campoScelto">
                <div id="info_btn" onClick={apri_info} style={{ display: "flex",alignItems: "center",justifyContent: "center",cursor:"pointer",width:"220px",textAlign:"center"}}>
                    <i className="fa-solid fa-circle-info" style={{marginRight:"10px"}}></i>
                    <span  style={{color:"var(--text)"}}>Informazioni</span>
                </div>
                <div> 
                    <div id="campoNomeScelto">
                        Campo 1
                    </div>
                    
                    <div id="campoNomeScelto" onClick={()=>{
                        window.location.reload(false)
                    }} style={{cursor:"pointer"}}>
                        <span id="nome" style={{userSelect:"none"}}>Cambia campo</span>
                    </div>
                </div>
                <div style={{display:"flex",justifyContent:"center",flexDirection:"column",alignItems:"center"}}>
                    <input type="button" name="" id="tutte_pren" value="Tutte le prenotazioni" onClick={()=>{popup_vedi_pren("tutte")}} style={{width:"230px",marginBottom:"10px"}}/>
                    <input type="button" name="" id="mie_pren" value="Le tue prenotazioni" onClick={()=>{popup_vedi_pren("singola")}}/>
                </div>
            </div>
            <Informazioni/>
            <div id="tabella-cont">
                <div id="tabella">
                    <div id="orari">
                        <div className='titolo'>
                            Orari
                        </div>
                        {orari}
                    </div>
                    <div id="giorni">
                        {giorni}
                        {giornipren}
                    </div> 
                </div>
            </div>
        </div>
    )
}

export default Prenota