import React from 'react'
import "./Alert.css"

const Alert = (props) => {
    // const close=()=>{
    //     document.querySelector("#alert").style.display="none"
    // }
    return (
        <div id="alert-cont">
            <div id="alert">
                {/* <i className="fa-solid fa-xmark" onClick={close}></i> */}
                <div>
                    <i className="fa-solid fa-triangle-exclamation"></i>
                    <h1>Attenzione</h1>
                </div>
                <div>
                    {props.messaggio}
                </div>
            </div>
        </div>
    )
}

export default Alert