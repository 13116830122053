import "./InfoLegali.css"
const InfoLegali = () => {
    return (
        <div id="info-legal">
            <div>Rag.Soc. ASD SPORTING CLUB</div>
            <div>Sede: Via San Bernardino 28, Saluzzo (CN)</div>
            <div>P.IVA: 03708820042</div>
            <div>C.F. 94047140044</div>
        </div>
    )
}

export default InfoLegali