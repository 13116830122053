import React, { useEffect, useState, createContext } from 'react'
import {BrowserRouter as Router, Route, Routes,useNavigate } from 'react-router-dom';
import {server,debug_mode} from "./Config"
import logo from "./img/loghi/logo-sporting.png"
import "./Main.css"
import Navbar from './componenti/Navbar'
import Footer from './componenti/Footer'
import Home from './Home'
import Login from './Login'
import Prenota from './Prenota'
import Profilo from './Profilo'
import Sudinoi from './Sudinoi'
import Privacy from "./Privacy"

export const Account=createContext()

const Main = () => {
    const [persona, setpersona] = useState({email:"",nome:"",cognome:"",username:"",nascita:"",socio:false});
    
    useEffect(() => { //SPLASHSCREEN
        if(document.querySelector("#splashscreen")!=null){
            setTimeout(() => {
                document.querySelector("#splashscreen").style.opacity = "0"
            }, 1000);
            setTimeout(() => {
                document.querySelector("#splashscreen").remove()
            }, 1200);
        }
        if(localStorage.getItem("username") != null){
            if(persona.email === ""){
                accedi_account()
            }
        }
    }, [])

    // useEffect(() => { //check
    //     console.log(persona)
    // }, [persona])
    
    const accedi_account=()=>{
        let link=server['url']+"cerca_utente.php"
        var formDataLogin = new FormData();
        formDataLogin.append('cerca_username', localStorage.getItem("username"));
        formDataLogin.append('username', localStorage.getItem("username"));
        formDataLogin.append('password', localStorage.getItem("pwd"))
        fetch(link,{ method: 'POST', body: formDataLogin })
        .then(function (response) {
            return response.text();
        })
        .then(function (body) {
            if(debug_mode) console.log(body)

            if(!body.includes("errate") && !body.includes("non presente")){
                let dati=JSON.parse(body)
                document.querySelector("#user-nav").innerHTML=dati[0].nome.split(" ")[0]
                setpersona(dati[0])
            }
        });
    }

    return (
        <Account.Provider value={{persona,setpersona}}>
            <Router>
            <div>
                <Navbar/>
                    <div id="splashscreen">
                        <div id="racchetta">
                            
                        </div>
                        <div className='logo'>
                            <img src={logo} alt="logo"/>
                        </div>
                    </div>
                    <Routes>
                        <Route path="/" element={<Home/>}/>
                        <Route path={persona.nome=="" ? "/login" : ""} element={<Login/>}/>
                        <Route path="/prenota" element={<Prenota/>}/>
                        <Route path={persona.nome=="" ? "/profilo" : "/login"} element={<Profilo/>}/>
                        <Route path="/su-di-noi" element={<Sudinoi/>}/>
                        <Route path="/privacy" element={<Privacy/>}/>
                    </Routes>
                <Footer/>
            </div>
            </Router>
        </Account.Provider>
    )
}

export default Main