import React from 'react'
import "./Sudinoi.css"
import logoItis from "./img/loghi/logoitis.png"
import logoDenina from "./img/loghi/denina.png"

const Sudinoi = () => {
    document.title="Sporting - Su di noi"
    return (
        <div id="su-di-noi">
            <div className='logo'>
                <img src={logoItis} className="logodenina" alt="logo"/>
                <img src={logoDenina} alt="logo" style={{marginLeft:"30%"}}/>
            </div>
            <p>Questa Web App è stata realizzata dall'<b>IIS Denina Pellico Rivoira</b>, sezione <b>ITIS Rivoira.</b></p>
            <a href="https://www.denina.it">Vieni a trovarci</a>
            <p id="by">Creato da Rera Alessio e Castaldo Antonio</p>
            <p id='warning'>I creatori del sito non si prendono alcuna responsabilità legale di eventuali perdite di dati.</p>
        </div>
    )
}

export default Sudinoi